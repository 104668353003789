html,body {
  height:100vh;
  outline: none;
}

body {
  background-color: #333;
}

#fullQuestion {
  min-height: 100vh;
}

#title {
  text-align: center;
  float: left;
  color: black;
  background: #f6c733;
  padding: 0px 10px;
  margin-bottom: none !important;
}

.footertext {
  margin: 0;
}

.main {
  background-color: blue;
  /* flex: auto; */
  display: flex;
}

.feed {
  padding: 20px;
  position: absolute;
  right: 0;
  width: 75%;
}

.updown {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
#uploadFile {
  border-radius: 5px;
  border: 1px solid;
  border-radius: 5px;
  border: #222222;
  color: white;
  background-color: #111111;
  max-width: 100px;
  outline: none;
}

#searchBar {
  margin-left: 15%;
  display: inline-block;
  padding: 10px;
  width: 50%;
  border: black;
  border: 1px solid;
  border-radius: 5px;
  outline: none;
  margin-right: 10px;
  border-top: 50px;
}

#titleArea {
  padding: 20px;
  margin: none;
  width: 100%;
  z-index: 9;
  position: fixed;
}

.sidePanel {
  display: block;
  color: white;
  background-color: #111;
  text-align: left;
  width: 25%;
  height: 100%;
  position: fixed;
}

.alertcenter {
  width: 50%;
  position: fixed;
  left: 0;
  right: 0;
  margin-left: auto;
  margin-right: auto;
  z-index: 100;
}

.alert {
  border: 1px solid #f6c733;
  border-radius: 5px;
  color: #000;
  background-color: #f6c733;
}

.closed {
  display: none;
}

.notification p {
  margin: 0;
}

.open {
  display: block;
}

.link-dark {
  color: white;
  font-size: 15px;
}

.link-light {
  color: black;
  font-size: 15px;
}

.link-dark:hover {
  color: white;
}

.link-light:hover {
  color: black;
}

.sbox {
  margin-bottom: 20px;
  padding: 10px;
}

.copyright {
  font-size: 10px;
}


button {
  outline: none;
}
.questionBox {
  margin-top: 5px;
  margin-bottom: 5px;
  text-align: left;
  padding-bottom: 2px;
  display: block;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
}


#places {
  text-align: center;
  margin: 40%;
  margin-top: 12px;
  margin-bottom: 10px;
  padding-bottom: 2px;
  display: inline-block;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 20%;
  outline: none;
  color: white;
  
  
}
.tickBoxes {
  color: white;
}

#checkBoxSelect {
  text-align: left;
  margin: 10%;
  margin-top: 12px;
  margin-bottom: 10px;
  padding-bottom: 2px;
  display: inline-block;
  padding: 10px;
  border: 1px solid #fff;
  border-radius: 5px;
  width: 80%;
  outline: none;
  color: white;
}

#anonymousBoxLabel{
  
}

#checkBoxTitle{
  text-align: center;
  align-content: center;
  font-size: 20px;
}



/* SPACERS */

#spacer1 {
  padding: 10px;
}




.tickBoxSurround {
  display: inline-block;
  width: 200px;
}


#submitClasses {
  margin-left: 40%;
  width: 20%;
}
.tickBoxes {
  
}
.upvotes-num {
  text-align: center;
  margin: 0;
}

#answerBox {
  border: solid;
  padding: 10px;  
  border-width: 1px;
  border-radius: 5px;
  margin-bottom: 10px;
  outline: none !important;
  
}

#replyBox {
  border: solid;
  padding: 10px;  
  border-width: 1px;
  border-radius: 5px;
  height: 100px;
  width: 100%;
}

.questionBox:hover {
  cursor: pointer;
  
}

.aboutname {
  font-size: 1em;
}

.feed-list {
  list-style-type: none;
  height: 100%;
  padding: 10px;
}

@media(max-width: 800px) {
  .questionBox {
    margin: 0;
    margin-top: 5px;
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
    width: 100%;
  }

  .feed-list .container li:last-of-type {
    border-bottom: none;
  }

  .sidePanel {
    display: none;
  }


  #profileMenu {
    float: right !important;
  }

  

  
  .cardcontainer {
    flex-direction: column;
  }

  .container .card {
    margin-top: 10px;
    margin-bottom: 10px;
  }

}

#socialDrop {
  float: right !important;
  margin-top: 0px;

}

#searchBar {
  margin-left: 0;
  height: 50px;
  margin-left: 15%;
}


#logOut {
  /* margin: 0; */
  display: inline-block;
  
  float: right;
  border-radius: 100%;
  width: 50px;
}
#logIn {
  /* margin: 0; */
  display: inline-block;
  
  /* width: 10% !important; */
  /* margin-top: -46px; */
  float: right;
  height: 50px;

  /* width: 50px; */
}


a {
  font-weight: bold;
  color: #0070d1;
}

.seeFull {
  display: inline-block;
  float:right;
  margin-top: -30px;
  margin-right: 10px;
}

.voteButton {
  text-align: none !important;
  outline: none !important; 
  margin-top: 0px !important;
  

  display: block;
  border: none;
  padding: none;
  padding-left: 10px;
}

#middleText {
  text-align: center;
}

.footer {
  padding: 30px;
  width: 100%;
  position: relative;
  bottom: 0;
}

.links {
  font-weight: bold;
  color: #0070d1;
  text-decoration: none;
  -moz-user-select: none;
   -khtml-user-select: none;
   -webkit-user-select: none;
}
.links:hover {
  text-decoration: underline;
}

.loader {
  margin: 25%;
  margin-left: 30%;
}

.cardcontainer {
  display: flex;
  flex-direction: row;
}

#profileMenu {
  float: right;
  margin-top: 45px !important;
}

.post-img {
  max-height: 500px;
}